import React, { useState, useMemo } from "react"
import { navigate, useStaticQuery, graphql, Link } from "gatsby"

const CityFooter = props => {
  const data = useStaticQuery(graphql`
    query {
      allSanityCity(sort: { fields: [city], order: ASC }) {
        nodes {
          slug {
            current
          }
          city
          state {
            slug {
              current
            }
            state
          }
        }
      }
    }
  `)

  const cities = data.allSanityCity.nodes.filter(
    city => city?.state?.state == props?.state
  )
  const [showMore, setShowMore] = useState(false)

  return (
    <div className="componentContainer">
      <div className="corporative-footer-container">
        <div className="corporative-footer-container-title">
          <p>Search for climate change risks by city</p>
        </div>
        <div className="corporative-footer-container-list-link">
          <div className="corporative-footer-container-col">
            {cities.map((city, index) => {
              {
                if (index > 27 && !showMore) {
                  return (
                    <div
                      key={index}
                      className="corpotative-footer-container-list cityItem"
                    >
                      <Link
                        className="corporative-footer-container-col-link"
                        to={`/${city.slug.current}`}
                      >
                        {city.city}
                      </Link>
                    </div>
                  )
                } else {
                  return (
                    <div
                      key={index}
                      className="corpotative-footer-container-list"
                    >
                      <Link
                        className="corporative-footer-container-col-link"
                        to={`/${city.slug.current}`}
                      >
                        {city.city}
                      </Link>
                    </div>
                  )
                }
              }
            })}
          </div>
          {cities.length > 28 && (
            <div className="cityShowMore">
              <span onClick={() => setShowMore(!showMore)}>
                <p>{showMore ? "Show less" : "Show more"}</p>
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CityFooter
