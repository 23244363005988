import React from "react"
import { BaseBlockContent2 } from "@utils/richTextS"
import * as styles from "./StateDescription.module.scss"

const StateDescription = ({ description }) => {
  return (
    <section className={styles.stateDescription}>
      <div className={styles.container}>
        <BaseBlockContent2 blocks={description} />
      </div>
    </section>
  )
}

export default StateDescription
