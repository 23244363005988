import React from "react"
import BackgroundImage from "gatsby-background-image"
import { BaseBlockContent } from "@utils/richText"
import * as styles from "./Hero.module.scss"

const Hero = ({ state, herobg, title }) => {
  return (
    <BackgroundImage
      fluid={herobg.childImageSharp.fluid}
      className={styles.heroCityContainer}
    >
      <div className={styles.heroCityWrapper}>
        <div className={styles.heroCityContent}>
          <span className={styles.ourCity}>{`${state}`}</span>
          <h1 className={styles.headline}>
            {title[0].children[0].text}
          </h1>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default Hero
