import React from "react"
import { BaseBlockContent2 } from "@utils/richTextS"
import * as styles from "./ActionGuide.module.scss"

const ActionGuide = ({ content }) => {
  return (
    <section className={styles.actionGuide}>
      <div className={styles.container}>
        <div className={styles.content}>
          <BaseBlockContent2 blocks={content} />
        </div>
      </div>
    </section>
  )
}

export default ActionGuide
