import React from "react"
import SEO from "@components/SEO"
import { graphql } from "gatsby"
import CorporativeHeader from "@components/global/CorporativeHeader"
import CitySection from "@components/StatePage/CitySection"
import Hero from "../../components/StatePage/Hero"
import StateDescription from "../../components/StatePage/StateDescription"
import Footer from "@components/global/Footer"
import RiskAssessment from "@components/global/RiskAssessment"
import ActionGuide from "@components/CityPage/ActionGuide"
import "@styles/Components/state.scss"
import CityFooter from "@components/global/CorporativeFooter/CityFooter"

const StateBySlugPage = ({ data: { sanityNewState, herobg } }) => {
  const { content } = sanityNewState
  const meta = sanityNewState.seo
    ? Object.entries(sanityNewState.seo).map(([key, value]) => {
        return { name: key, content: value }
      })
    : []

  const firstSection = content[0]
  const restSections = content.slice(1)

  return (
    <>
      <SEO meta={meta} seo={sanityNewState.seo} />
      <CorporativeHeader />
      {sanityNewState._rawTitle && (
        <Hero
          state={sanityNewState.state}
          title={sanityNewState._rawTitle}
          herobg={herobg}
        />
      )}
      {sanityNewState._rawTitleDescription && (
        <StateDescription description={sanityNewState._rawTitleDescription} />
      )}

      {firstSection && <CitySection section={firstSection} />}

      {restSections.length > 0 && <RiskAssessment />}

      {restSections.map((section, i) => {
        return <CitySection key={i} section={section} />
      })}

      {sanityNewState.actionGuide && (
        <ActionGuide content={sanityNewState.actionGuide} />
      )}

      <RiskAssessment />
      <CityFooter state={sanityNewState.state} />
      <Footer />
    </>
  )
}
export default StateBySlugPage

export const query = graphql`
  query StateQuery($slug: String!) {
    sanityNewState(slug: { current: { eq: $slug } }) {
      state
      _rawTitle(resolveReferences: { maxDepth: 10 })
      _rawTitleDescription(resolveReferences: { maxDepth: 10 })
      actionGuide: _rawActionGuideContent(resolveReferences: { maxDepth: 10 })
      slug {
        current
      }
      seo {
        title
        description
        keywords
      }
      content {
        ... on SanityCitySection {
          title: _rawTitle(resolveReferences: { maxDepth: 10 })
          leftBody: _rawBodyLeft(resolveReferences: { maxDepth: 10 })
          rightBody: _rawBodyRight(resolveReferences: { maxDepth: 10 })
          background
        }
      }
    }

    herobg: file(relativePath: { eq: "img-min/Hero-AboutUs-1.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
